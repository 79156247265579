import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Slider from 'root/webapp/shared/component/Slider';
import { TweenMax } from 'gsap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import vm from '../vip.controller';

const aniList = [];
const vipIcon = [];

class Vip extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        setTimeout(() => {
            vm.getVipBannerElement().then((data) => {
                vipIcon.length = 0;

                for (let i = 0; i < data.length; i++) {
                    vipIcon.push(data[i]);
                }
            });
        }, 500);

        setTimeout(() => {
            vm.loadVipLandingPage();
            vm.loadMemberGroupAutomationSummary();
            vm.getLevelMsg();
        }, 100);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    // animation effect
    doSuspendAni(index) {
        aniList.forEach((item) => item.kill());
        aniList.length = 0;
        const idList = vipIcon.map((item, j) => `#suspend_${index}_${j}`);

        const endStyleStore = [];
        const randomItem = (list) => {
            return list[0 + Math.round(Math.random() * (list.length - 1 - 0))];
        };

        TweenMax.delayedCall(0.02, () => {
            idList.map((id, k) => {
                const $item = document.querySelector(id);
                endStyleStore[k] = {
                    left: getComputedStyle($item).left,
                    bottom: getComputedStyle($item).bottom,
                };

                TweenMax.set(id, {
                    x: -parseFloat(getComputedStyle($item).width) / 2,
                    y: [2, 3, 4, 10].includes(index) ? '100%' : 0,
                    bottom: 0,
                    left: '50%',
                    opacity: 0,
                    rotation: randomItem([-80, -40, 40, 80, 100]),
                    onComplete: () => {
                        TweenMax.set(id, {
                            left: getComputedStyle($item).left,
                        });

                        TweenMax.to(id, 0.8, {
                            x: 0,
                            y: 0,
                            left: endStyleStore[k].left,
                            bottom: endStyleStore[k].bottom,
                            opacity: 1,
                            rotation: 0,
                            delay: 0.2 * k + 0.5,
                            onComplete: () => {
                                TweenMax.set(id, { left: '', bottom: '' });
                                if (k == idList.length - 1) {
                                    idList.map((id) => {
                                        aniList.push(
                                            TweenMax.to(id, randomItem([5, 6, 7]), {
                                                x: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                y: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                rotation: randomItem([15, 10, -10, -5]),
                                                yoyo: true,
                                                repeat: -1,
                                            })
                                        );
                                    });
                                }
                            },
                        });
                    },
                });
            });
        });
    }

    render() {
        const { landingTranslate, table, memberGroupLevel } = this.state;

        return (
            <Translation>
                {(t) => (
                    <div className='vip-panel'>
                        {this.state.list.length > 0 && <Slider autoPlay={false} onChange={this.doSuspendAni} items={this.state.list} forceStart={true} />}

                        <div className='vip-box'>
                            <div className='vip-status'>
                                {/* <div className='status-img' style={{ backgroundImage: 'url(' + this.state.memberGroupImg + ')' }} /> */}
                                <LazyLoadImage className='status-img' src={this.state.memberGroupImg} />
                                <div className='dummy-col' />

                                <div className='progress-status'>
                                    <div className='current-status'>
                                        <span className='status-title'>{t('vip:vip.currentStatus', 'Current Status')}</span>
                                        <div className='progress-bar'>
                                            <span style={{ width: this.state.progressBarPercentage }}></span>
                                        </div>
                                    </div>

                                    <div className='status-level'>
                                        {memberGroupLevel.map((level, i) => (
                                            <div key={i} className={'all-level level-' + level.toLowerCase()}>
                                                {/* <p data-level={level}>{level}</p> */}

                                                {this.state.memberGroupLevelMessages.map((msg, i) => (
                                                    <Fragment key={i}>
                                                        <div className='memberGroupLevelArrowContainer'>
                                                            <hr width='1' size='18' className={'level-' + level.toLowerCase()}></hr>
                                                        </div>
                                                        {/* <div className='memberGroupLevelArrowContainer'>
                              <div className={'memberGroupLevelArrow level-' + level.toLowerCase()}></div>
                            </div> */}
                                                        <p data-level={level}>{msg[level + '-translate']}</p>
                                                        <span key={msg} dangerouslySetInnerHTML={{ __html: msg[level] }}></span>
                                                    </Fragment>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <table className='vip-table'>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        {memberGroupLevel.map((level, i) => (
                                            <td key={i}>
                                                <LazyLoadImage src={vm.getLevelCard(level && level.toLowerCase())} />
                                            </td>
                                        ))}
                                    </tr>

                                    {landingTranslate && (
                                        <Fragment>
                                            <tr className='table-type'>
                                                <td className='type-title' dangerouslySetInnerHTML={{ __html: landingTranslate.table.sub_title }}></td>

                                                {landingTranslate.table &&
                                                    (landingTranslate.table.groupLevel || []).map((group, i) => (
                                                        <td key={i} className={group.className} style={group.style ? group.style : {}}>
                                                            {group.desc}
                                                        </td>
                                                    ))}
                                            </tr>

                                            {table.map((content, index) => (
                                                <Fragment key={index}>
                                                    {content.title && (
                                                        <tr>
                                                            <td
                                                                className='content-title'
                                                                dangerouslySetInnerHTML={{ __html: landingTranslate.table.category[content.title] }}
                                                            ></td>
                                                        </tr>
                                                    )}

                                                    {content.data.map((row, index) => (
                                                        <tr key={index} className={row?.className || ''}>
                                                            {row.colTitle && <td dangerouslySetInnerHTML={{ __html: landingTranslate.table.col[row.colTitle] }}></td>}
                                                            {row.col.map((col, index) =>
                                                                vm.isEnableCol1(col) ? (
                                                                    <td key={index} dangerouslySetInnerHTML={{ __html: landingTranslate.table.col[col] }}></td>
                                                                ) : !vm.isEnableCol1(col) && vm.isEnableCol2(col) ? (
                                                                    <td
                                                                        key={index}
                                                                        colSpan={`${col !== 'spInv2' && '5'}`}
                                                                        rowSpan={`${col === 'spInv2' && '2'}`}
                                                                        dangerouslySetInnerHTML={{ __html: landingTranslate.table.col[col] }}
                                                                    ></td>
                                                                ) : (
                                                                    !vm.isEnableCol2(col) && (
                                                                        <td key={index} dangerouslySetInnerHTML={{ __html: col !== null ? col : '-' }}></td>
                                                                    )
                                                                )
                                                            )}
                                                        </tr>
                                                    ))}
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>

                            <LazyLoadImage className='vip-btm' src='/public/html/images/vip/vip-bottom.png' />
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('vip')(withRouter(Vip)));
