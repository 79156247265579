import transactionHelper from 'root/utils/transaction-helper';
import notification from 'root/utils/notification';
import commonUtil from '../../../../../../utils/common-util';
import { gu_loadCustomTranslation, gu_togglePopup } from '../../../../../../utils/general-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
const METHOD_OR_CHANNEL_CODE = 'RQR';
const IS_GET_LATEST_MERCHANT_BANK = true;

// NOTE: watch out there're merchant bank & member bank, two different things.
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            isLoading: false,
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            depositMinMaxLimit: { min: 0, max: 0 },
            depositAmountOptions: [],

            bankList: [],
            selectedBankIndex: 0,
            selectedMemberBankIndex: 0,
            selectedChannelIndex: 0,
            hoveredIndex: null,
            depositOptions: [],

            // should be can put at parent de variables
            memberBankList: [],
            showOnlineBankDetails: false,
            receiptImg: [],
            fields: {
                amount: '',
                reference: '',
            },
            bankNameErrMsg: [],
            errors: {},
            addBankDialogIsShow: false,
            disableDepositButton: false,
            isFormValid: false,
            accountBankfields: {
                selectedBankIndex: 0,
                bankName: '',
                bankBranch: '',
                accountName: controller.vm.props.user.account.name,
                accountNumber: '',
            },
            showAmountField: true,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            compSettings: {},
            isShowNoticeBox: false,
            qrCodeDialogIsShow: false,
            selectedQrCodeImg: '',
            receiptImgErrMsg: 'transaction:transaction.deposit.receiptIsRequired',
            memberBankErrMsg: null,
            isInvalidUploadFileType: false,
            bankMaintenanceDisplay: [],

            bankType:METHOD_OR_CHANNEL_CODE,
        };
    },

    initCompSettings() {
        gu_loadCustomTranslation('regionQR', controller.vm);
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'regionQRSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
                depositPagesSettings: tempSettings.depositPagesSettings || {},
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                resolve();
            });
        });
    },

    setIsLoading(flag) {
        controller.vm.setState({ isLoading: flag });
    },

    // NOTE: callback is use for set isLoading
    // NOTE: --- merchant bank functions ---
    loadMerchantBank(callback) {
        const vm = controller.vm;
        const { user, filteredDepositApiJson, paymentGatewaySetting } = vm.props;

        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        // always get first one channel, because bank transfer currently only has 1 channel
        if (
            filteredDepositApiJson &&
            filteredDepositApiJson.optionCode === METHOD_OR_CHANNEL_CODE &&
            filteredDepositApiJson.channel &&
            Object.values(filteredDepositApiJson.channel) &&
            Object.values(filteredDepositApiJson.channel)[0]
        ) {
            let selectedMerchantBank = null;
            let merchantBankList = Object.values(filteredDepositApiJson.channel)[0];
            if (merchantBankList && merchantBankList.length > 0) {
                selectedMerchantBank = controller._getMerchantBank(merchantBankList);
                selectedMerchantBank.accountHolder = selectedMerchantBank.accountName;
                selectedMerchantBank.bankCode = selectedMerchantBank.code;
            }

            // massage the merchant bank list
            window.SPL_Transaction.getBankMaintenanceStatusV2([selectedMerchantBank]).then(function (res) {
                const merchantBank = res[0];
                const { showAmountField, isFormValid } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, merchantBank);

                vm.setState(
                    {
                        selectedMerchantBank,
                        showAmountField,
                        isFormValid,
                    },
                    () => {
                        // NOTE: QRQ = B, hence can use back instead of create new. (in case BE insist)
                        commonUtil.getDepositMinMax(vm, 'B', user.account.currency, vm.state.selectedMerchantBank.id);
                        controller._getMerchantBankStatus();

                        callback && callback();
                    }
                );
            });
        }
    },

    // NOTE: --- private functions ---
    _getMerchantBankStatus() {
        const vm = controller.vm;
        const { selectedMerchantBank } = vm.state;

        const currency = vm.props.user.account.currency;
        window.SPL_Transaction.processBankMaintenanceDisplay([selectedMerchantBank], currency).then((data) => {
            let showBankStatus = false;

            const info = data;
            for (let i = 0; i < info.length; i++) {
                const currentInfo = info[i];
                if (currentInfo.displayMaintenances.length > 0) {
                    for (let j = 0; j < currentInfo.displayMaintenances.length; j++) {
                        const currentDisplayMaintenances = currentInfo.displayMaintenances[j];
                        if (currentDisplayMaintenances.prefix === 'DAILY') {
                            currentDisplayMaintenances.prefix = 'transaction:transaction.deposit.bankMaintenance.daily';
                        } else if (currentDisplayMaintenances.prefix === 'WEEKDAY') {
                            currentDisplayMaintenances.prefix = 'transaction:transaction.deposit.bankMaintenance.weekday';
                        } else if (currentDisplayMaintenances.prefix === 'WEEKEND') {
                            currentDisplayMaintenances.prefix = 'transaction:transaction.deposit.bankMaintenance.weekend';
                        } else if (currentDisplayMaintenances.prefix === 'SAT') {
                            currentDisplayMaintenances.prefix = 'transaction:transaction.deposit.bankMaintenance.sat';
                        } else if (currentDisplayMaintenances.prefix === 'SUN') {
                            currentDisplayMaintenances.prefix = 'transaction:transaction.deposit.bankMaintenance.sun';
                        } else if (currentDisplayMaintenances.prefix === 'EMERGENCY') {
                            currentDisplayMaintenances.prefix = 'transaction:transaction.deposit.bankMaintenance.emergency';
                        }
                    }
                    showBankStatus = true;
                }
            }

            vm.setState({
                showBankStatus,
                bankMaintenanceDisplay: info,
            });
        });
    },

    _getMerchantBank(merchantBankList) {
        const merchantBank = IS_GET_LATEST_MERCHANT_BANK ? merchantBankList[0] : merchantBankList[merchantBankList.length - 1];
        return merchantBank;
    },

    // NOTE: callback is use for set isLoading
    // NOTE: --- member bank account functions ---
    loadMemberBankAccount(callback) {
        const vm = controller.vm;

        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank(vm.props).then((data) => {
            const { memberBankList, unOwnedBankList, bankList } = data;
            for (let i = 0; i < memberBankList.length; i++) {
                const memberBank = memberBankList[i];
                memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
            }

            let depositSettings = vm.state.depositAllSettings;
            if (depositSettings && depositSettings.allowOneMemberBankOnly) {
                depositSettings.hideAddBankAccount = memberBankList && memberBankList.length >= 1 ? true : false;
            }

            // bank-transfer page need filter out bank type R
            const _unOwnedBankList = unOwnedBankList.filter(function (e) {
                return e.bankType !== 'R';
            });
            const _memberBankList = memberBankList.filter(function (e) {
                return e.bankCode !== 'TM';
            });

            vm.setState(
                {
                    bankList: bankList,
                    memberBankList: _memberBankList,
                    unOwnedBankList: _unOwnedBankList,
                    depositAllSettings: depositSettings,
                },
                () => {
                    controller.hideShowOnlineBank(() => {
                        controller.handleReceiptValidation();
                    });

                    callback && callback();
                }
            );
        });
    },

    hideShowOnlineBank(callback) {
        const vm = controller.vm;
        const { depositAllSettings, showOnlineBankDetails, selectedMemberBankIndex, memberBankList } = vm.state;
        const { t } = vm.props;

        if (depositAllSettings && depositAllSettings.allowOnlineBankDetails) {
            vm.setState({ showOnlineBankDetails: true }, () => {
                // if showOnlineBankDetails true, need to check if member have bank first
                let memberBankErrMsg = null;
                if (showOnlineBankDetails || (depositAllSettings && depositAllSettings.showOnlineBankingOnly)) {
                    let memberBankIndex = memberBankList[selectedMemberBankIndex];
                    if (!memberBankIndex) {
                        memberBankErrMsg = t('transaction:transaction.deposit.cashdeposit.promptAddBankAcc', 'Please add your bank account');
                    }
                }
                vm.setState({ memberBankErrMsg });

                callback && callback();
            });
        } else {
            callback && callback();
        }
    },

    handleReceiptImageChange(e) {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            const receipt = e.target.files[0];
            window.SPL_Transaction.validateReceiptImage(receipt).then((data) => {
                const vm = controller.vm;
                const { isInvalidUploadFileType, errMsg } = data;
                if (isInvalidUploadFileType) {
                    vm.setState({
                        isInvalidUploadFileType: isInvalidUploadFileType,
                        receiptImgErrMsg: errMsg,
                        receiptImg: [],
                        receiptImgFile: null,
                        disableDepositButton: true,
                    });
                } else {
                    vm.setState(
                        {
                            receiptImg: URL.createObjectURL(receipt),
                            receiptImgFile: receipt,
                            isInvalidUploadFileType: false,
                        },
                        () => {
                            controller.handleReceiptValidation();
                        }
                    );
                }
            });
        }
    },

    handleReceiptValidation() {
        const vm = controller.vm;
        const { showOnlineBankDetails, depositAllSettings, bankList, selectedMemberBankIndex, receiptImg } = vm.state;

        let selectedBankObj = null;
        if ((showOnlineBankDetails || (depositAllSettings && depositAllSettings.showOnlineBankingOnly)) && bankList.length > 0) {
            selectedBankObj = bankList[selectedMemberBankIndex];
        }

        let isChannelReqReceipt = true;
        if (depositAllSettings && depositAllSettings.receiptNotMandatoryChannels && depositAllSettings.receiptNotMandatoryChannels.indexOf(METHOD_OR_CHANNEL_CODE) !== -1) {
            isChannelReqReceipt = false;
        }

        if (
            depositAllSettings &&
            (isChannelReqReceipt ||
                (selectedBankObj && depositAllSettings.receiptMandatoryBankCodes && depositAllSettings.receiptMandatoryBankCodes.indexOf(selectedBankObj.bankCode) !== -1)) &&
            receiptImg.length === 0
        ) {
            vm.setState({
                receiptRequired: true,
                isReceiptValid: false,
                disableDepositButton: true,
            });
        } else {
            vm.setState({
                receiptRequired: false,
                isReceiptValid: true,
                disableDepositButton: false,
            });
        }
    },

    handleBankDetailChange(e) {
        transactionHelper.handleBankDetailChange(controller.vm, e);
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.QR]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
            }
        } else {
            transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
        }
    },

    getCorrectDepositMinMaxLimit() {
        const { depositAllSettings } = controller.vm.state;
        const currency = controller.vm.props.user.account.currency;
        let _depositMinMaxLimit = { min: 0, max: 0 };

        if (depositAllSettings && depositAllSettings.depositJson && depositAllSettings.depositJson.onlinetransfer) {
            _depositMinMaxLimit = depositAllSettings.depositJson.onlinetransfer[currency];
        }
        controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    },

    submitForm() {
        const { isFormValid, disableDepositButton, isReceiptValid } = controller.vm.state;
        if (isFormValid && !disableDepositButton && isReceiptValid) {
            // validate form again before proceed
            controller.submitDeposit();
        }
    },

    submitDeposit() {
        controller.vm.setState({ disableDepositButton: true });
        const { selectedMemberBankIndex, selectedMerchantBank } = controller.vm.state;
        const { receiptImgFile, memberBankList } = controller.vm.state;
        const { fields } = controller.vm.state;

        let merchantBankObj = selectedMerchantBank;
        let merchantBankId = merchantBankObj.id;
        let memberBankDetails = null;

        if (memberBankList[selectedMemberBankIndex] && memberBankList[selectedMemberBankIndex].memberBankId) {
            memberBankDetails = memberBankList[selectedMemberBankIndex].memberBankId;
        }

        let picFile = receiptImgFile;

        window.SPL_Transaction.localPaySubmit(null, null, merchantBankId, fields.amount, METHOD_OR_CHANNEL_CODE, null, memberBankDetails, picFile, fields.reference).then(
            (data) => {
                if (data.status === 'S') {
                    transactionHelper.resetForm(controller.vm);
                    controller.vm.setState({ disableDepositButton: false });
                    notification.showNotification('success', controller.vm.props.t('transaction:transaction.deposit.request-success'));
                } else {
                    let errorMsg = '';
                    if (data.code === 'receipt_fail') {
                        errorMsg = 'transaction:transaction.withdrawal.request-uploadReceiptError';
                    } else if (data.msg.data.detail === 'Merchant Bank inactive or not belong to member bank group.') {
                        errorMsg = 'transaction:transaction.withdrawal.request-bankInactive';
                    } else if (data.code === 'fail_deposit' || data.msg.data.status === 409 || data.msg.data.status === 403 || data.msg.data.status === 500) {
                        errorMsg = 'transaction:transaction.withdrawal.request-pending';
                    } else {
                        errorMsg = 'transaction:transaction.withdrawal.request-error';
                    }
                    controller.vm.setState({ disableDepositButton: false });
                    notification.showNotification('error', controller.vm.props.t(errorMsg));
                }
            }
        );
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'rqr' : '';
        transactionHelper.readCustomDepositArticle(controller.vm, customFilename);
    },

    togglePopup(component) {
        gu_togglePopup(controller.vm, component);
    },

    addBankSuccess() {
        controller.togglePopup('add-bank');
        transactionHelper.loadMemberBankAccounts(controller.vm);
    },

    onImportantNoticeIconHover(flag) {
        controller.vm.setState({ isShowNoticeBox: flag });
    },

    onImportantNoticeIconClick() {
        controller.vm.setState({ isShowNoticeBox: !controller.vm.state.isShowNoticeBox });
    },

    openQrCodeDialog(qrcode) {
        controller.vm.setState({
            qrCodeDialogIsShow: true,
            selectedQrCodeImg: qrcode,
        });
    },

    closeQrCodeDialog() {
        controller.vm.setState({ qrCodeDialogIsShow: false });
    },
};

export default controller;
